

import { convertToUtc } from '@utils/convertToUtc';
import { ProcessedEvent } from '@aldabil/react-scheduler/types';



export const aggiungiEventoCalendarioOutlook = (event?: ProcessedEvent, trattativa?: Trattativa) => {
    if (event){
        const eventTitle = encodeURIComponent(event.title);
        const startDateTime = event.start.toISOString();
        const endDateTime =event.end.toISOString(); 
        const eventDetails = encodeURIComponent(event.nota + '\n\nCellulare: ' + event.cellulare);
        const outlookCalendarUrl = `https://outlook.office.com/calendar/0/action/compose?allday=false&body=&enddt=${endDateTime}&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startDateTime}&subject=${eventTitle}&body=${eventDetails}`;
        window.open(outlookCalendarUrl, '_blank');
    }else if (trattativa){
        const eventTitle = encodeURIComponent(trattativa.lead?.azienda + ": " + trattativa.stato?.nome || "");
        const startDateTime =(new Date(trattativa.dataReminder)).toISOString();
        const endDateTime =(new Date(trattativa.dataReminder)).toISOString(); 
        const eventDetails = encodeURIComponent(trattativa.note + '\n\nCellulare: ' + trattativa.lead?.cellulare);
        const outlookCalendarUrl = `https://outlook.office.com/calendar/0/action/compose?allday=false&body=&enddt=${endDateTime}&location=&path=%2Fcalendar%2Faction%2Fcompose&rru=addevent&startdt=${startDateTime}&subject=${eventTitle}&body=${eventDetails}`;
        
        window.open(outlookCalendarUrl, '_blank');
    }
}


