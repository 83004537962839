import Divider from '@mui/material/Divider';
import {
    Timeline, TimelineItem, TimelineSeparator,
    TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent
} from '@mui/lab';
import { Chip, styled, Button, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import LabelColorata from '@components/object/LabelColorata';
import DeleteIcon from '@mui/icons-material/Delete';
import SettingsIcon from '@mui/icons-material/Settings';
import useStateAlertStore from '../store/stateAlertStore';
import useConfermaPopUpStore from '@components/store/confermaPopUpStore';
import useTrattativeStore from '@components/store/trattativeStore';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import { aggiungiEventoCalendarioGoogle } from '@utils/calendario/aggiungiEventoCalendarioGoogle';
import { aggiungiEventoCalendarioOutlook } from '@utils/calendario/aggiungiEventoCalendarioOutlook';
import GoogleIcon from '@mui/icons-material/Google';

type Props = {
    openTrattativaModal(trattativaID: number): void,
}

const CircularButton = styled(Button)({
    minWidth: '0px',
    padding: '4px',
    borderRadius: '50%',
    backgroundColor: '#b0b0b0', // Sfondo grigio
    '&:hover': {
        backgroundColor: '#a0a0a0', // Sfondo grigio scuro quando hover
    },
    marginRight: '3px'
});

const SmallDeleteIcon = styled(DeleteIcon)({
    fontSize: '18px', // Dimensione dell'icona ridotta
});
const SmallSettingsIcon = styled(SettingsIcon)({
    fontSize: '18px', // Dimensione dell'icona ridotta
});

const SmallEditCalendarIcon = styled(EditCalendarIcon)({
    fontSize: '18px', // Dimensione dell'icona ridotta
});

const SmallGoogleIcon = styled(GoogleIcon)({
    fontSize: '18px', // Dimensione dell'icona ridotta
});


const StoriaTrattativa: React.FC<Props> = ({ openTrattativaModal }) => {
    const showAlert = useStateAlertStore(state => state.showAlert)
    const openConfermaPopUp = useConfermaPopUpStore(state => state.openDialog)
    const { trattative, fetchTrattativeByLead, deleteTrattativa } = useTrattativeStore(state => state);

    const eliminaSingolaTrattativa = (idTrattativa: number) => {
        openConfermaPopUp({
            domanda: "Vuoi eliminare davvero la trattativa? Ci sarà un buco nella storia delle trattative",
            titolo: "ATTENZIONE, possibile perdita di dati!",
            isOpen: true,
            id: idTrattativa,
            callback: (idTrattativa) => {
                deleteTrattativa(idTrattativa).then((res: TResponseStatus) => {
                    showAlert({ isOpen: true, severity: res.error ? 'error' : 'success', message: res.message })
                });
            }
        })
    };

    const modificaTrattiva = (trattativaID: number) => {
        openTrattativaModal(trattativaID)
    }
    return (
        <>
            <Divider><b>Storia della trattativa</b></Divider>
            <Timeline>
                {trattative?.map((trattativa, index) => (
                    <TimelineItem key={index}>
                        <TimelineOppositeContent color="text.secondary">
                            <CircularButton variant="contained" onClick={() => eliminaSingolaTrattativa(trattativa.id)}>
                                <Tooltip title="Elimina">
                                    <SmallDeleteIcon />
                                </Tooltip>
                            </CircularButton>
                            <CircularButton variant="contained" onClick={() => modificaTrattiva(trattativa.id)}>
                                <Tooltip title="Modifica">
                                    <SmallSettingsIcon />
                                </Tooltip>
                            </CircularButton>
                            {trattativa.dataReminder &&
                                <CircularButton variant="contained" onClick={() => aggiungiEventoCalendarioGoogle(undefined, trattativa)}>
                                    <Tooltip title="Aggiungi a Google Calendar">
                                            <SmallGoogleIcon />
                                    </Tooltip>
                                </CircularButton>
                            }
                            
                             {trattativa.dataReminder &&
                                <CircularButton variant="contained" onClick={() => aggiungiEventoCalendarioOutlook(undefined, trattativa)}>
                                    <Tooltip title="Aggiungi a Outlook Calendar">
                                        <SmallEditCalendarIcon />
                                    </Tooltip>
                                </CircularButton>
                            }
                            <Chip label={format(new Date(trattativa.createAt), 'dd/MM/yyyy HH:mm')} />
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot sx={{ backgroundColor: trattativa.stato?.colore }} />
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                            <LabelColorata nome={trattativa.stato?.nome || ""} colore={trattativa.stato?.colore || ""} />
                            <label><b>Nota: </b></label>
                            {trattativa.note}</TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
        </>
    )
}

export default StoriaTrattativa;