import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { PieValueType } from '@mui/x-charts';

// type Props{

// }

type Props = {
    data: Array<PieValueType>
}

export default function GraficoTorta({ data }: Props) {
  return (
    <PieChart
      series={[
        {data: data},
      ]}
      width={400}
      height={200}
    />
  );
}
