import {  Button, Tooltip, styled } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { aggiungiEventoCalendarioGoogle } from '@utils/calendario/aggiungiEventoCalendarioGoogle';
import { ProcessedEvent } from '@aldabil/react-scheduler/types';
import { aggiungiEventoCalendarioOutlook } from '@utils/calendario/aggiungiEventoCalendarioOutlook';
import GoogleIcon from '@mui/icons-material/Google';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
interface Props {
    event: ProcessedEvent;
}

const clickTrattative = (trattativaID: number) => {
    window.open("/gestione-trattative/" + trattativaID, "_blank");
};

const SmallEditCalendarIcon = styled(EditCalendarIcon)({
    fontSize: '18px', // Dimensione dell'icona ridotta
});

const SmallGoogleIcon = styled(GoogleIcon)({
    fontSize: '18px', // Dimensione dell'icona ridotta
});

const RiepigoloEvento: React.FC<Props> = ({ event }: Props) => {
    return (
        <>
            <Tooltip title="Apri storia trattative">
                <Button variant="contained" onClick={() => clickTrattative(event.leadID)} sx={{ marginRight: 1 }}> <VisibilityIcon /></Button>
            </Tooltip>
            <Tooltip title="Aggiungi evento a Google Calendar">
                <Button variant="contained" onClick={() => aggiungiEventoCalendarioGoogle(event)} sx={{ marginRight: 1 }}> <SmallGoogleIcon/> </Button>
            </Tooltip>
            <Tooltip title="Aggiungi evento a Outlook Calendar">
                <Button variant="contained" onClick={() => aggiungiEventoCalendarioOutlook(event)}> <SmallEditCalendarIcon /> </Button>
            </Tooltip>
            
            
            <p><b>Nota:</b> <br /> {event.nota || "Nessuna..."}</p>
            <p><b>Cellulare:</b> {event.cellulare}</p>
            <p><b>Completato:</b> {event.completato ? "Sì" : "No"}</p>
        </>
    )
}
export default RiepigoloEvento;
