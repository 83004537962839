import { useState, useEffect } from 'react';
import { Container, Grid, Paper } from '@mui/material';
import AuthorizeView from "@components/Authorization/AuthorizeView"
import CardInformazione from '@components/object/CardInformazione';
import Calendario from '@components/Calendario/Calendario';
import { fetchLeads } from '@api/apiLeads';
import { fetchUtenti } from '@api/apiUtenti';
import { USER_ADMIN } from '@config/constant';
import GraficoTorta from '@components/grafici/GraficoTorta';
import paper from 'styles/Paper.module.css'
import { PieValueType } from '@mui/x-charts';

const Home: React.FC = () => {

    const [leads, setLeads] = useState<Lead[]>([]);
    const [users, setUsers] = useState<Utente[]>([]);
    const [riepilogoUtenti, setRiepilogoUtenti] = useState<Array<PieValueType>>([]);

    useEffect(() => {

        fetchLeads().then(leads => {
            setLeads(leads);
            fetchUtenti().then(utenti => {
                setUsers(utenti);
                setRiepilogoUtenti(getRiepilogoUtenti(utenti, leads));
            });
        });
    }, []);

    function getRiepilogoUtenti(utenti: Utente[], leads: Lead[]): Array<PieValueType> {
        let riepilogoUtenti: Array<PieValueType> = [];
        utenti.filter((utente) => utente.nome != USER_ADMIN).map((utente, index) => {
            riepilogoUtenti.push({
                id: index,
                value: leads.filter((lead) => lead.utenteId == utente.id).length,
                label: utente.nome
            })
        });

        
        return riepilogoUtenti;
    }
    return (
        <AuthorizeView>
            <div className='container'>
                <div className='row'>
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={2} lg={2}>
                                <Paper className={paper.style}>
                                    <CardInformazione titolo='Lead Generati' valore={leads.length} sottoTitolo='Ad oggi'></CardInformazione>
                                </Paper>
                            </Grid>

                            {users.filter((utente) => utente.nome != USER_ADMIN).map((utente, index) => {
                                return (
                                    <Grid item xs={12} md={2} lg={2} key={index}>
                                        <Paper className={paper.style}>
                                            <CardInformazione titolo={`Lead di ${utente.nome}`} valore={leads.filter((lead) => lead.utenteId == utente.id).length} sottoTitolo='Ad oggi'></CardInformazione>
                                        </Paper>
                                    </Grid>
                                )
                            })}
                            <Grid item xs={12} md={3} lg={3}>
                                <Paper className={paper.style}>
                                    <CardInformazione titolo='Nuovi contatti' valore={leads.filter((lead) => lead.utente == null).length} ></CardInformazione>
                                </Paper>
                            </Grid>
                        </Grid>
                        <Calendario />
                        <Paper>
                            <GraficoTorta data={riepilogoUtenti} />
                        </Paper>
                        
                    </Container>
                </div>
            </div>
        </AuthorizeView>
    );
}

export default Home;